@charset "UTF-8";

.clear {
  clear: both; }

.visible {
  visibility: visible; }

.hidden {
  visibility: hidden; }

.dblock {
  display: block; }

.dnone {
  display: none; }

.pc {
  display: block; }
@media screen and (max-width: 960px) {
  .pc {
    display: none; } }

.sp {
  display: none; }
@media screen and (max-width: 960px) {
  .sp {
    display: block; } }

/* DarkBackground.tsx */
.bg--item {
  height: 4274px;
  position: relative;
  z-index: 2;
  opacity: 0.3; }
@media screen and (max-width: 960px) {
  .bg--item {
    height: 3654px;
  }
}
.bg--item_object {
  position: absolute;
}
.bg--item_object img {
  opacity: 0.4;
}
.bg--item_object .item {
  opacity: 0.4;
}
.bg--item_object_01 {
  width: 0;
  height: 516px;
  top: 140px;
  left: 50%;
  margin-left: -630px;
  overflow: hidden;
  position: relative;
  -webkit-transition: 1.7s ease;
  transition: 1.7s ease;
}
@media screen and (max-width: 960px) {
  .bg--item_object_01 {
    height: 382px;
    top: 356px;
    margin-left: -400px; } }
.bg--item_object_01.inview {
  width: 732px; }
@media screen and (max-width: 960px) {
  .bg--item_object_01.inview {
    width: 582px; } }
.bg--item_object_02 {
  top: 643px;
  left: 50%;
  margin-left: -374px; }
@media screen and (max-width: 960px) {
  .bg--item_object_02 {
    top: 2461px;
    left: auto;
    right: 50%;
    margin-left: auto;
    margin-right: -188px; } }
.bg--item_object_03 {
  top: 366px;
  left: 50%;
  margin-left: -557px; }
@media screen and (max-width: 960px) {
  .bg--item_object_03 {
    top: 462px;
    margin-left: -154px; } }
.bg--item_object_03.inview {
  -webkit-animation: flash 4s 2s linear infinite;
  animation: flash 4s 2s linear infinite; }
.bg--item_object_04 {
  top: 179px;
  right: 50%;
  margin-right: -636px; }
@media screen and (max-width: 960px) {
  .bg--item_object_04 {
    top: 1986px;
    right: auto;
    margin-right: auto;
    left: 50%;
    margin-left: -214px; } }
.bg--item_object_05 {
  width: 589px;
  height: 418px;
  top: 606px;
  right: 50%;
  margin-right: -580px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
@media screen and (max-width: 960px) {
  .bg--item_object_05 {
    width: 240px;
    height: 170px;
    top: 552px;
    left: auto;
    margin-left: auto;
    right: 50%;
    margin-right: -181px; } }
@-webkit-keyframes rollActive {
  0% {
    height: 0%; }
  100% {
    height: 100%; } }
@keyframes rollActive {
  0% {
    height: 0%; }
  100% {
    height: 100%; } }
.bg--item_object_05 .item {
  width: 2px;
  height: 0%;
  background-color: #0000ff; }
.bg--item_object_05.inview .item:nth-child(1) {
  -webkit-animation: rollActive 0.3s ease 0.05s 1 forwards;
  animation: rollActive 0.3s ease 0.05s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(2) {
  -webkit-animation: rollActive 0.3s ease 0.1s 1 forwards;
  animation: rollActive 0.3s ease 0.1s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(3) {
  -webkit-animation: rollActive 0.3s ease 0.15s 1 forwards;
  animation: rollActive 0.3s ease 0.15s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(4) {
  -webkit-animation: rollActive 0.3s ease 0.2s 1 forwards;
  animation: rollActive 0.3s ease 0.2s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(5) {
  -webkit-animation: rollActive 0.3s ease 0.25s 1 forwards;
  animation: rollActive 0.3s ease 0.25s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(6) {
  -webkit-animation: rollActive 0.3s ease 0.3s 1 forwards;
  animation: rollActive 0.3s ease 0.3s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(7) {
  -webkit-animation: rollActive 0.3s ease 0.35s 1 forwards;
  animation: rollActive 0.3s ease 0.35s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(8) {
  -webkit-animation: rollActive 0.3s ease 0.4s 1 forwards;
  animation: rollActive 0.3s ease 0.4s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(9) {
  -webkit-animation: rollActive 0.3s ease 0.45s 1 forwards;
  animation: rollActive 0.3s ease 0.45s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(10) {
  -webkit-animation: rollActive 0.3s ease 0.5s 1 forwards;
  animation: rollActive 0.3s ease 0.5s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(11) {
  -webkit-animation: rollActive 0.3s ease 0.55s 1 forwards;
  animation: rollActive 0.3s ease 0.55s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(12) {
  -webkit-animation: rollActive 0.3s ease 0.6s 1 forwards;
  animation: rollActive 0.3s ease 0.6s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(13) {
  -webkit-animation: rollActive 0.3s ease 0.65s 1 forwards;
  animation: rollActive 0.3s ease 0.65s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(14) {
  -webkit-animation: rollActive 0.3s ease 0.7s 1 forwards;
  animation: rollActive 0.3s ease 0.7s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(15) {
  -webkit-animation: rollActive 0.3s ease 0.75s 1 forwards;
  animation: rollActive 0.3s ease 0.75s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(16) {
  -webkit-animation: rollActive 0.3s ease 0.8s 1 forwards;
  animation: rollActive 0.3s ease 0.8s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(17) {
  -webkit-animation: rollActive 0.3s ease 0.85s 1 forwards;
  animation: rollActive 0.3s ease 0.85s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(18) {
  -webkit-animation: rollActive 0.3s ease 0.9s 1 forwards;
  animation: rollActive 0.3s ease 0.9s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(19) {
  -webkit-animation: rollActive 0.3s ease 0.95s 1 forwards;
  animation: rollActive 0.3s ease 0.95s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(20) {
  -webkit-animation: rollActive 0.3s ease 1s 1 forwards;
  animation: rollActive 0.3s ease 1s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(21) {
  -webkit-animation: rollActive 0.3s ease 1.05s 1 forwards;
  animation: rollActive 0.3s ease 1.05s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(22) {
  -webkit-animation: rollActive 0.3s ease 1.1s 1 forwards;
  animation: rollActive 0.3s ease 1.1s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(23) {
  -webkit-animation: rollActive 0.3s ease 1.15s 1 forwards;
  animation: rollActive 0.3s ease 1.15s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(24) {
  -webkit-animation: rollActive 0.3s ease 1.2s 1 forwards;
  animation: rollActive 0.3s ease 1.2s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(25) {
  -webkit-animation: rollActive 0.3s ease 1.25s 1 forwards;
  animation: rollActive 0.3s ease 1.25s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(26) {
  -webkit-animation: rollActive 0.3s ease 1.3s 1 forwards;
  animation: rollActive 0.3s ease 1.3s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(27) {
  -webkit-animation: rollActive 0.3s ease 1.35s 1 forwards;
  animation: rollActive 0.3s ease 1.35s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(28) {
  -webkit-animation: rollActive 0.3s ease 1.4s 1 forwards;
  animation: rollActive 0.3s ease 1.4s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(29) {
  -webkit-animation: rollActive 0.3s ease 1.45s 1 forwards;
  animation: rollActive 0.3s ease 1.45s 1 forwards; }
.bg--item_object_05.inview .item:nth-child(30) {
  -webkit-animation: rollActive 0.3s ease 1.5s 1 forwards;
  animation: rollActive 0.3s ease 1.5s 1 forwards; }
.bg--item_object_06 {
  top: 1660px;
  right: 50%;
  margin-right: -1237px; }
@media screen and (max-width: 960px) {
  .bg--item_object_06 {
    top: 2144px;
    margin-right: -266px; } }
.bg--item_object_07 {
  top: 1731px;
  left: 50%;
  margin-left: -714px; }
@-webkit-keyframes flash {
  0% {
    opacity: 0; }
  1% {
    opacity: 1; }
  2% {
    opacity: 0; }
  3% {
    opacity: 1; }
  4% {
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes flash {
  0% {
    opacity: 0; }
  1% {
    opacity: 1; }
  2% {
    opacity: 0; }
  3% {
    opacity: 1; }
  4% {
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    opacity: 1; } }
.bg--item_object_07.inview {
  -webkit-animation: flash 7s linear infinite;
  animation: flash 7s linear infinite; }
.bg--item_object_08 {
  top: 1873px;
  right: 50%;
  margin-right: -890px; }
@media screen and (max-width: 960px) {
  .bg--item_object_08 {
    top: 3200px;
    margin-right: -274px; } }
.bg--item_object_09 {
  top: 2472px;
  left: 50%;
  margin-left: -716px; }
@media screen and (max-width: 960px) {
  .bg--item_object_09 {
    top: 3040px;
    margin-left: -578px; } }
.bg--item_object_10 {
  top: 2729px;
  left: 50%;
  margin-left: -816px; }
.bg--item_object_11 {
  top: 2586px;
  right: 50%;
  margin-right: -653px; }
.bg--item_object_11.inview {
  -webkit-animation: flash 7s 4s linear infinite;
  animation: flash 7s 4s linear infinite; }
.bg--item_object_12 {
  top: 3076px;
  left: 50%;
  margin-left: -726px; }
.bg--item_object_12.inview {
  -webkit-animation: flash 3.4s 6s linear infinite;
  animation: flash 3.4s 6s linear infinite; }
.bg--item_object_13 {
  width: 943px;
  height: 257px;
  top: 3200px;
  right: 50%;
  margin-right: -920px; }
@media screen and (max-width: 960px) {
  .bg--item_object_13 {
    width: 340px;
    height: 93px;
    top: 2780px;
    left: 50%;
    right: auto;
    margin-right: auto;
    margin-left: -252px; } }
.bg--item_object_13 .item {
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  -webkit-transform: translate(60px, -60px);
  -ms-transform: translate(60px, -60px);
  transform: translate(60px, -60px);
  width: 805px;
  height: 118px; }
@media screen and (max-width: 960px) {
  .bg--item_object_13 .item {
    width: 290px;
    height: 43px; } }
.bg--item_object_13 .item:first-child {
  left: 83px;
  top: 55px;
  border: 3px solid #00ff00;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }
@media screen and (max-width: 960px) {
  .bg--item_object_13 .item:first-child {
    top: 20px;
    left: 30px;
    border: 1px solid #00ff00; } }
.bg--item_object_13 .item:nth-child(2) {
  top: 83px;
  left: 55px;
  border: 3px solid #ff0000;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s; }
@media screen and (max-width: 960px) {
  .bg--item_object_13 .item:nth-child(2) {
    top: 30px;
    left: 20px;
    border: 1px solid #ff0000; } }
.bg--item_object_13 .item:nth-child(3) {
  top: 111px;
  left: 28px;
  border: 3px solid #00ff00;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s; }
@media screen and (max-width: 960px) {
  .bg--item_object_13 .item:nth-child(3) {
    top: 40px;
    left: 10px;
    border: 1px solid #00ff00; } }
.bg--item_object_13 .item:nth-child(4) {
  top: 139px;
  left: 0;
  border: 3px solid #ff0000;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s; }
@media screen and (max-width: 960px) {
  .bg--item_object_13 .item:nth-child(4) {
    top: 50px;
    border: 1px solid #ff0000; } }
.bg--item_object_13 .item:nth-child(5) {
  top: 0;
  right: 0;
  opacity: 0.6;
  background-color: #ff0000;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }
.bg--item_object_13.inview .item {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }


main {
  padding-top: 108px;
  -ms-overflow-style: none;
  overflow: hidden; }
main::-webkit-scrollbar {
  display: none; }
@media screen and (max-width: 960px) {
  main {
    padding-top: 70px; } }
main section {
  width: 960px;
  margin: 0 auto; }
@media screen and (max-width: 960px) {
  main section {
    width: 100%;
    padding: 0 10px; } }

/* GoalAmountArea */
.target--white {
  color: #fff;
}
.target--visual {
  padding-left: 22px; }
@media screen and (max-width: 960px) {
  .target--visual {
    padding-left: 10px; } }
.target--visual span {
  font-size: 14px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: auto;
  white-space: nowrap; }
@media screen and (max-width: 960px) {
  .target--visual span {
    font-size: 10px; } }
.target--visual .target_name {
  padding-right: 2px; }
@media screen and (max-width: 960px) {
  .target--visual .target_name {
    padding-right: 0; } }
.target--visual .target_data {
  padding: 0 4px;
  letter-spacing: .1em;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .target--visual .target_data {
    letter-spacing: .1em;
    font-size: 12px;
  }
}

/* RemainingDaysArea */
.days span {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center; }
.days span.days_data {
  padding-right: 5px; }
.days--white {
  color: #fff; }
.days--black {
  color: #1a1a1a; }
.days--visual {
  margin-top: 13px;
  padding-left: 20px;
  margin-bottom: 30px; }
@media screen and (max-width: 960px) {
  .days--visual {
    padding-left: 25px; } }
.days--visual .days_data {
  font-size: 20px;
  font-weight: 800;
  font-style: italic; }
@media screen and (max-width: 960px) {
  .days--visual .days_data {
    font-size: 18px; } }
.days--visual .days_unit {
  font-size: 14px;
  font-weight: 800; }
@media screen and (max-width: 960px) {
  .days--visual .days_unit {
    font-size: 12px; } }
.days--list span {
  font-size: 12px; }
@media screen and (max-width: 960px) {
  .days--list span {
    font-size: 10px; } }
@media screen and (max-width: 960px) {
  .days--list span.days_data {
    padding-right: 0; } }
.days--message {
  margin-left: 30px; }
.days--message .days_data {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  font-size: 10px;
  color: #fff; }
.days--message .days_unit {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  font-size: 10px;
  color: #fff; }

/* RegisterButtonとか */
.link {
  width: 100%; }
.link a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative; }
@-webkit-keyframes textFlash {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px); }
  1% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  2% {
    opacity: 0;
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  3% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  4% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  5% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); } }
@keyframes textFlash {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px); }
  1% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  2% {
    opacity: 0;
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  3% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  4% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  5% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); } }
@media screen and (min-width: 961px) {
  .link a:hover .link_upper--item:first-child {
    -webkit-animation: gritchUpper01 1.2s 0s infinite step-start;
    animation: gritchUpper01 1.2s 0s infinite step-start; }
  .link a:hover .link_upper--item:nth-child(2) {
    -webkit-animation: gritchUpper02 1.2s 0s infinite step-start;
    animation: gritchUpper02 1.2s 0s infinite step-start; }
  .link a:hover .link_upper--item:nth-child(3) {
    -webkit-animation: gritchUpper03 1.2s 0s infinite step-start;
    animation: gritchUpper03 1.2s 0s infinite step-start; }
  .link a:hover .link_upper--item:nth-child(4) {
    -webkit-animation: gritchUpper04 1.2s 0s infinite step-start;
    animation: gritchUpper04 1.2s 0s infinite step-start; }
  .link a:hover .link_upper--item:nth-child(5) {
    -webkit-animation: gritchUpper05 1.2s 0s infinite step-start;
    animation: gritchUpper05 1.2s 0s infinite step-start; }
  .link a:hover .link_upper--item:nth-child(6) {
    -webkit-animation: gritchUpper06 1.2s 0s infinite step-start;
    animation: gritchUpper06 1.2s 0s infinite step-start; }
  .link a:hover .link_upper_gritch_01 {
    -webkit-animation: gritchUpper07 1.2s 0s infinite step-start;
    animation: gritchUpper07 1.2s 0s infinite step-start; }
  .link a:hover .link_upper_gritch_02 {
    -webkit-animation: gritchUpper08 1.2s 0s infinite step-start;
    animation: gritchUpper08 1.2s 0s infinite step-start; }
  .link a:hover .link_text {
    -webkit-animation: textFlash 2s infinite;
    animation: textFlash 2s infinite; }
  .link a:hover .link_lower--item:first-child {
    -webkit-animation: gritchLower01 1.2s 0s infinite step-start;
    animation: gritchLower01 1.2s 0s infinite step-start; }
  .link a:hover .link_lower--item:nth-child(2) {
    -webkit-animation: gritchLower02 1.2s 0s infinite step-start;
    animation: gritchLower02 1.2s 0s infinite step-start; }
  .link a:hover .link_lower--item:nth-child(3) {
    -webkit-animation: gritchLower03 1.2s 0s infinite step-start;
    animation: gritchLower03 1.2s 0s infinite step-start; }
  .link a:hover .link_lower--item:nth-child(4) {
    -webkit-animation: gritchLower04 1.2s 0s infinite step-start;
    animation: gritchLower04 1.2s 0s infinite step-start; }
  .link a:hover .link_lower--item:nth-child(5) {
    -webkit-animation: gritchLower05 1.2s 0s infinite step-start;
    animation: gritchLower05 1.2s 0s infinite step-start; }
  .link a:hover .link_lower--item:nth-child(6) {
    -webkit-animation: gritchLower06 1.2s 0s infinite step-start;
    animation: gritchLower06 1.2s 0s infinite step-start; }
  .link a:hover .link_lower_gritch_01 {
    -webkit-animation: gritchLower07 1.2s 0s infinite step-start;
    animation: gritchLower07 1.2s 0s infinite step-start; }
  .link a:hover .link_lower_gritch_02 {
    -webkit-animation: gritchLower08 1.2s 0s infinite step-start;
    animation: gritchLower08 1.2s 0s infinite step-start; } }
.link--list {
  height: 38px; }
.link--list .link_text p {
  font-size: 16px; }
@media screen and (max-width: 960px) {
  .link--list .link_text p {
    font-size: 14px; } }
.link--list .link_upper--item:nth-child(4) {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1); }
.link--list .link_lower--item:nth-child(2) {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1); }
.link_upper {
  width: calc(100% - 6px);
  height: 50%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 6px; }
.link_upper--item {
  position: absolute; }
.link_upper--item:first-child {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: #00ff00;
  top: 2px;
  right: 2px; }
@-webkit-keyframes gritchUpper01 {
  0% {
    width: calc(100% - 2px);
    right: 2px; }
  5% {
    width: calc(100% - 2px);
    right: -10px; }
  10% {
    width: calc(100% - 2px);
    right: 20px; }
  15% {
    width: calc(100% - 12px);
    right: -10px; }
  20% {
    width: calc(100% - 2px);
    right: 2px; }
  100% {
    width: calc(100% - 2px);
    right: 2px; } }
@keyframes gritchUpper01 {
  0% {
    width: calc(100% - 2px);
    right: 2px; }
  5% {
    width: calc(100% - 2px);
    right: -10px; }
  10% {
    width: calc(100% - 2px);
    right: 20px; }
  15% {
    width: calc(100% - 12px);
    right: -10px; }
  20% {
    width: calc(100% - 2px);
    right: 2px; }
  100% {
    width: calc(100% - 2px);
    right: 2px; } }
.link_upper--item:nth-child(2) {
  width: calc(100% - 4px);
  height: 100%;
  background-color: #ff0000;
  top: 0;
  left: 0; }

@-webkit-keyframes gritchUpper02 {
  0% {
    width: calc(100% - 4px);
    left: 0; }
  5% {
    width: calc(100% - 4px);
    left: 12px; }
  10% {
    width: calc(100% - 4px);
    left: -18px; }
  15% {
    width: calc(100% - 14px);
    left: 18px; }
  20% {
    width: calc(100% - 4px);
    left: 0; }
  100% {
    width: calc(100% - 4px);
    left: 0; } }

@keyframes gritchUpper02 {
  0% {
    width: calc(100% - 4px);
    left: 0; }
  5% {
    width: calc(100% - 4px);
    left: 12px; }
  10% {
    width: calc(100% - 4px);
    left: -18px; }
  15% {
    width: calc(100% - 14px);
    left: 18px; }
  20% {
    width: calc(100% - 4px);
    left: 0; }
  100% {
    width: calc(100% - 4px);
    left: 0; } }
.link_upper--item:nth-child(3) {
  width: calc(100% - 6px);
  height: calc(100% - 2px);
  top: 2px;
  left: 2px;
  background-color: #ffff00; }

@-webkit-keyframes gritchUpper03 {
  0% {
    width: calc(100% - 6px);
    left: 2px; }
  5% {
    width: calc(100% - 8px);
    left: 14px; }
  10% {
    width: calc(100% - 10px);
    left: -18px; }
  15% {
    width: calc(100% - 18px);
    left: 20px; }
  20% {
    width: calc(100% - 6px);
    left: 2px; }
  100% {
    width: calc(100% - 6px);
    left: 2px; } }

@keyframes gritchUpper03 {
  0% {
    width: calc(100% - 6px);
    left: 2px; }
  5% {
    width: calc(100% - 8px);
    left: 14px; }
  10% {
    width: calc(100% - 10px);
    left: -18px; }
  15% {
    width: calc(100% - 18px);
    left: 20px; }
  20% {
    width: calc(100% - 6px);
    left: 2px; }
  100% {
    width: calc(100% - 6px);
    left: 2px; } }
.link_upper--item:nth-child(4) {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  top: 4px;
  right: 0;
  background-color: #0000ff; }

@-webkit-keyframes gritchUpper04 {
  0% {
    width: calc(100% - 4px);
    right: 0; }
  5% {
    width: calc(100% - 4px);
    right: -12px; }
  10% {
    width: calc(100% - 4px);
    right: 18px; }
  15% {
    width: calc(100% - 14px);
    right: -12px; }
  20% {
    width: calc(100% - 4px);
    right: 0; }
  100% {
    width: calc(100% - 4px);
    right: 0; } }

@keyframes gritchUpper04 {
  0% {
    width: calc(100% - 4px);
    right: 0; }
  5% {
    width: calc(100% - 4px);
    right: -12px; }
  10% {
    width: calc(100% - 4px);
    right: 18px; }
  15% {
    width: calc(100% - 14px);
    right: -12px; }
  20% {
    width: calc(100% - 4px);
    right: 0; }
  100% {
    width: calc(100% - 4px);
    right: 0; } }
.link_upper--item:nth-child(5) {
  width: calc(100% - 6px);
  height: calc(100% - 4px);
  top: 4px;
  right: 2px;
  background-color: #00ffff; }

@-webkit-keyframes gritchUpper05 {
  0% {
    width: calc(100% - 6px);
    right: 2px; }
  5% {
    width: calc(100% - 6px);
    right: -10px; }
  10% {
    width: calc(100% - 6px);
    right: 20px; }
  15% {
    width: calc(100% - 16px);
    right: -10px; }
  20% {
    width: calc(100% - 6px);
    right: 2px; }
  100% {
    width: calc(100% - 6px);
    right: 2px; } }

@keyframes gritchUpper05 {
  0% {
    width: calc(100% - 6px);
    right: 2px; }
  5% {
    width: calc(100% - 6px);
    right: -10px; }
  10% {
    width: calc(100% - 6px);
    right: 20px; }
  15% {
    width: calc(100% - 16px);
    right: -10px; }
  20% {
    width: calc(100% - 6px);
    right: 2px; }
  100% {
    width: calc(100% - 6px);
    right: 2px; } }
.link_upper--item:nth-child(6) {
  width: calc(100% - 8px);
  height: calc(100% - 4px);
  background-color: #fff;
  top: 4px;
  left: 4px; }

@-webkit-keyframes gritchUpper06 {
  0% {
    width: calc(100% - 8px);
    left: 4px; }
  5% {
    width: calc(100% - 13px);
    left: 21px; }
  10% {
    width: calc(100% - 13px);
    left: -13px; }
  15% {
    width: calc(100% - 18px);
    left: 22px; }
  20% {
    width: calc(100% - 8px);
    left: 4px; }
  100% {
    width: calc(100% - 8px);
    left: 4px; } }

@keyframes gritchUpper06 {
  0% {
    width: calc(100% - 8px);
    left: 4px; }
  5% {
    width: calc(100% - 13px);
    left: 21px; }
  10% {
    width: calc(100% - 13px);
    left: -13px; }
  15% {
    width: calc(100% - 18px);
    left: 22px; }
  20% {
    width: calc(100% - 8px);
    left: 4px; }
  100% {
    width: calc(100% - 8px);
    left: 4px; } }
.link_upper_gritch {
  position: absolute;
  opacity: 0; }
.link_upper_gritch_01 {
  width: 36px;
  height: 2px;
  background-color: #fff;
  top: 14px;
  left: 15px; }

@-webkit-keyframes gritchUpper07 {
  0% {
    top: 14px;
    left: 11px;
    opacity: 0; }
  5% {
    top: 14px;
    left: 11px;
    opacity: 1; }
  10% {
    top: 14px;
    left: 11px;
    opacity: 0; }
  15% {
    top: 7px;
    left: 8px;
    opacity: 1; }
  20% {
    top: 14px;
    left: 11px;
    opacity: 0; }
  100% {
    top: 14px;
    left: 15px;
    opacity: 0; } }

@keyframes gritchUpper07 {
  0% {
    top: 14px;
    left: 11px;
    opacity: 0; }
  5% {
    top: 14px;
    left: 11px;
    opacity: 1; }
  10% {
    top: 14px;
    left: 11px;
    opacity: 0; }
  15% {
    top: 7px;
    left: 8px;
    opacity: 1; }
  20% {
    top: 14px;
    left: 11px;
    opacity: 0; }
  100% {
    top: 14px;
    left: 15px;
    opacity: 0; } }
.link_upper_gritch_02 {
  width: 36px;
  height: 3px;
  background-color: #ff0000;
  top: 18px;
  left: -4px; }

@-webkit-keyframes gritchUpper08 {
  0% {
    top: 18px;
    left: -4px;
    opacity: 0; }
  5% {
    top: 18px;
    left: -4px;
    opacity: 1; }
  10% {
    top: 18px;
    left: -4px;
    opacity: 0; }
  15% {
    top: 36px;
    left: -30px;
    opacity: 1; }
  20% {
    top: 18px;
    left: -4px;
    opacity: 0; }
  100% {
    top: 18px;
    left: -4px;
    opacity: 0; } }

@keyframes gritchUpper08 {
  0% {
    top: 18px;
    left: -4px;
    opacity: 0; }
  5% {
    top: 18px;
    left: -4px;
    opacity: 1; }
  10% {
    top: 18px;
    left: -4px;
    opacity: 0; }
  15% {
    top: 36px;
    left: -30px;
    opacity: 1; }
  20% {
    top: 18px;
    left: -4px;
    opacity: 0; }
  100% {
    top: 18px;
    left: -4px;
    opacity: 0; } }
.link_text {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 3;
  text-align: center; }
.link_text p {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-style: italic;
  font-size: 24px;
  color: #1a1a1a; }
@media screen and (max-width: 960px) {
  .link_text p {
    font-size: 18px; } }
.link_lower {
  width: calc(100% - 6px);
  height: 50%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0; }
.link_lower--item {
  position: absolute; }
.link_lower--item:first-child {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: #00ff00;
  bottom: 2px;
  left: 2px; }

@-webkit-keyframes gritchLower01 {
  0% {
    width: calc(100% - 2px);
    left: 2px; }
  5% {
    width: calc(100% - 2px);
    left: -8px; }
  10% {
    width: calc(100%);
    left: 14px; }
  15% {
    width: calc(100% - 10px);
    left: -10px; }
  20% {
    width: calc(100% - 2px);
    left: 2px; }
  100% {
    width: calc(100% - 2px);
    left: 2px; } }

@keyframes gritchLower01 {
  0% {
    width: calc(100% - 2px);
    left: 2px; }
  5% {
    width: calc(100% - 2px);
    left: -8px; }
  10% {
    width: calc(100%);
    left: 14px; }
  15% {
    width: calc(100% - 10px);
    left: -10px; }
  20% {
    width: calc(100% - 2px);
    left: 2px; }
  100% {
    width: calc(100% - 2px);
    left: 2px; } }
.link_lower--item:nth-child(2) {
  width: calc(100% - 4px);
  height: 100%;
  background-color: #0000ff;
  bottom: 0;
  right: 0; }

@-webkit-keyframes gritchLower02 {
  0% {
    width: calc(100% - 4px);
    right: 0; }
  5% {
    width: calc(100%);
    right: 9px; }
  10% {
    width: calc(100% + 10px);
    right: -18px; }
  15% {
    width: calc(100% - 10px);
    right: 18px; }
  20% {
    width: calc(100% - 4px);
    right: 0; }
  100% {
    width: calc(100% - 4px);
    right: 0; } }

@keyframes gritchLower02 {
  0% {
    width: calc(100% - 4px);
    right: 0; }
  5% {
    width: calc(100%);
    right: 9px; }
  10% {
    width: calc(100% + 10px);
    right: -18px; }
  15% {
    width: calc(100% - 10px);
    right: 18px; }
  20% {
    width: calc(100% - 4px);
    right: 0; }
  100% {
    width: calc(100% - 4px);
    right: 0; } }
.link_lower--item:nth-child(3) {
  width: calc(100% - 6px);
  height: calc(100% - 2px);
  bottom: 2px;
  right: 2px;
  background-color: #00ffff; }

@-webkit-keyframes gritchLower03 {
  0% {
    width: calc(100% - 6px);
    right: 2px; }
  5% {
    width: calc(100% - 9px);
    right: 14px; }
  10% {
    width: calc(100% - 1px);
    right: -15px; }
  15% {
    width: calc(100% - 16px);
    right: 20px; }
  20% {
    width: calc(100% - 6px);
    right: 2px; }
  100% {
    width: calc(100% - 6px);
    right: 2px; } }

@keyframes gritchLower03 {
  0% {
    width: calc(100% - 6px);
    right: 2px; }
  5% {
    width: calc(100% - 9px);
    right: 14px; }
  10% {
    width: calc(100% - 1px);
    right: -15px; }
  15% {
    width: calc(100% - 16px);
    right: 20px; }
  20% {
    width: calc(100% - 6px);
    right: 2px; }
  100% {
    width: calc(100% - 6px);
    right: 2px; } }
.link_lower--item:nth-child(4) {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  bottom: 4px;
  left: 0;
  background-color: #ff0000; }

@-webkit-keyframes gritchLower04 {
  0% {
    width: calc(100% - 4px);
    left: 0; }
  5% {
    width: calc(100% - 4px);
    left: -12px; }
  10% {
    width: calc(100% - 1px);
    left: 12px; }
  15% {
    width: calc(100% - 14px);
    left: -12px; }
  20% {
    width: calc(100% - 4px);
    left: 0; }
  100% {
    width: calc(100% - 4px);
    left: 0; } }

@keyframes gritchLower04 {
  0% {
    width: calc(100% - 4px);
    left: 0; }
  5% {
    width: calc(100% - 4px);
    left: -12px; }
  10% {
    width: calc(100% - 1px);
    left: 12px; }
  15% {
    width: calc(100% - 14px);
    left: -12px; }
  20% {
    width: calc(100% - 4px);
    left: 0; }
  100% {
    width: calc(100% - 4px);
    left: 0; } }
.link_lower--item:nth-child(5) {
  width: calc(100% - 6px);
  height: calc(100% - 4px);
  bottom: 4px;
  left: 2px;
  background-color: #ffff00; }

@-webkit-keyframes gritchLower05 {
  0% {
    width: calc(100% - 6px);
    left: 2px; }
  5% {
    width: calc(100% - 4px);
    left: -12px; }
  10% {
    width: calc(100% - 4px);
    left: 14px; }
  15% {
    width: calc(100% - 16px);
    left: -10px; }
  20% {
    width: calc(100% - 6px);
    left: 2px; }
  100% {
    width: calc(100% - 6px);
    left: 2px; } }

@keyframes gritchLower05 {
  0% {
    width: calc(100% - 6px);
    left: 2px; }
  5% {
    width: calc(100% - 4px);
    left: -12px; }
  10% {
    width: calc(100% - 4px);
    left: 14px; }
  15% {
    width: calc(100% - 16px);
    left: -10px; }
  20% {
    width: calc(100% - 6px);
    left: 2px; }
  100% {
    width: calc(100% - 6px);
    left: 2px; } }
.link_lower--item:nth-child(6) {
  width: calc(100% - 8px);
  height: calc(100% - 4px);
  background-color: #fff;
  bottom: 4px;
  left: 4px; }

@-webkit-keyframes gritchLower06 {
  0% {
    width: calc(100% - 8px);
    left: 4px; }
  5% {
    width: calc(100% - 11px);
    left: -9px; }
  10% {
    width: calc(100% - 8px);
    left: 16px; }
  15% {
    width: calc(100% - 18px);
    left: -12px; }
  20% {
    width: calc(100% - 8px);
    left: 4px; }
  100% {
    width: calc(100% - 8px);
    left: 4px; } }

@keyframes gritchLower06 {
  0% {
    width: calc(100% - 8px);
    left: 4px; }
  5% {
    width: calc(100% - 11px);
    left: -9px; }
  10% {
    width: calc(100% - 8px);
    left: 16px; }
  15% {
    width: calc(100% - 18px);
    left: -12px; }
  20% {
    width: calc(100% - 8px);
    left: 4px; }
  100% {
    width: calc(100% - 8px);
    left: 4px; } }
.link_lower_gritch {
  position: absolute;
  opacity: 0; }
.link_lower_gritch_01 {
  width: 36px;
  height: 2px;
  background-color: #00ffff;
  top: 16px;
  right: 10px; }

@-webkit-keyframes gritchLower07 {
  0% {
    opacity: 0; }
  5% {
    opacity: 1; }
  10% {
    opacity: 0; }
  15% {
    opacity: 0; }
  20% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes gritchLower07 {
  0% {
    opacity: 0; }
  5% {
    opacity: 1; }
  10% {
    opacity: 0; }
  15% {
    opacity: 0; }
  20% {
    opacity: 0; }
  100% {
    opacity: 0; } }
.link_lower_gritch_02 {
  width: 36px;
  height: 3px;
  background-color: #fff;
  top: 21px;
  right: -3px; }
.link_lower_gritch_02:before, .link_lower_gritch_02:after {
  content: '';
  display: block;
  position: absolute;
  top: 0; }
.link_lower_gritch_02:before {
  width: 2px;
  height: 3px;
  right: -4px;
  background-color: #0000ff; }
.link_lower_gritch_02:after {
  width: 2px;
  height: 3px;
  right: -2px;
  background-color: #00ffff; }

@-webkit-keyframes gritchLower08 {
  0% {
    top: 21px;
    right: -3px;
    opacity: 0; }
  5% {
    top: 21px;
    right: -3px;
    opacity: 1; }
  10% {
    top: 21px;
    right: -3px;
    opacity: 0; }
  15% {
    top: 19px;
    right: 2px;
    opacity: 0; }
  20% {
    top: 21px;
    right: -3px;
    opacity: 0; }
  100% {
    top: 21px;
    right: -3px;
    opacity: 0; } }

@keyframes gritchLower08 {
  0% {
    top: 21px;
    right: -3px;
    opacity: 0; }
  5% {
    top: 21px;
    right: -3px;
    opacity: 1; }
  10% {
    top: 21px;
    right: -3px;
    opacity: 0; }
  15% {
    top: 19px;
    right: 2px;
    opacity: 0; }
  20% {
    top: 21px;
    right: -3px;
    opacity: 0; }
  100% {
    top: 21px;
    right: -3px;
    opacity: 0; } }
.link--submit {
  width: 100%;
  height: 44px;
  position: relative; }
.link--submit--item {
  position: absolute; }
.link--submit--item_bg {
  width: calc(100% - 10px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }
.link--submit--item_bg:before {
  content: '';
  width: 10px;
  height: calc(100% - 10px);
  position: absolute;
  top: 0;
  right: -10px; }
.link--submit--item_bg:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  bottom: 0;
  right: -10px;
  border-width: 10px 10px 0 0; }
.link--submit--item:first-child {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 1px;
  left: 1px; }
.link--submit--item:first-child .link--submit--item_bg {
  background-color: #00ff00; }
.link--submit--item:first-child .link--submit--item_bg:before {
  background-color: #00ff00; }
.link--submit--item:first-child .link--submit--item_bg:after {
  border-color: #00ff00 transparent transparent transparent; }
.link--submit--item:nth-child(2) {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 0;
  left: 0; }
.link--submit--item:nth-child(2) .link--submit--item_bg {
  background-color: #ff0000; }
.link--submit--item:nth-child(2) .link--submit--item_bg:before {
  background-color: #ff0000; }
.link--submit--item:nth-child(2) .link--submit--item_bg:after {
  border-color: #ff0000 transparent transparent transparent; }
.link--submit--item:nth-child(3) {
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  top: 1px;
  left: 1px; }
.link--submit--item:nth-child(3) .link--submit--item_bg {
  background-color: #ffff00; }
.link--submit--item:nth-child(3) .link--submit--item_bg:before {
  background-color: #ffff00; }
.link--submit--item:nth-child(3) .link--submit--item_bg:after {
  border-color: #ffff00 transparent transparent transparent; }
.link--submit--item:nth-child(4) {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 2px;
  left: 2px; }
.link--submit--item:nth-child(4) .link--submit--item_bg {
  background-color: #0000ff; }
.link--submit--item:nth-child(4) .link--submit--item_bg:before {
  background-color: #0000ff; }
.link--submit--item:nth-child(4) .link--submit--item_bg:after {
  border-color: #0000ff transparent transparent transparent; }
.link--submit--item:nth-child(5) {
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  top: 2px;
  left: 2px; }
.link--submit--item:nth-child(5) .link--submit--item_bg {
  background-color: #00ffff; }
.link--submit--item:nth-child(5) .link--submit--item_bg:before {
  background-color: #00ffff; }
.link--submit--item:nth-child(5) .link--submit--item_bg:after {
  border-color: #00ffff transparent transparent transparent; }
.link--submit--item:nth-child(6) {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  top: 2px;
  left: 2px; }
.link--submit--item:nth-child(6) .link--submit--item_bg {
  background-color: #fff; }
.link--submit--item:nth-child(6) .link--submit--item_bg:before {
  background-color: #fff; }
.link--submit--item:nth-child(6) .link--submit--item_bg:after {
  border-color: #fff transparent transparent transparent; }
.link--submit--item:nth-child(6) input {
  position: relative;
  z-index: 4; }
.link--submit .link_text input {
  width: 100%;
  height: 100%;
  font-style: italic;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }
@media screen and (min-width: 961px) {
  .link--submit:hover input {
    -webkit-filter: drop-shadow(-1px 0 0 #00ff00) drop-shadow(1px 0 0 #ff0000);
    filter: drop-shadow(-1px 0 0 #00ff00) drop-shadow(1px 0 0 #ff0000); } }

/* KeyVisualArea */
.visual_img {
  width: 650px; }
@media screen and (max-width: 960px) {
  .visual_img {
    width: 100%;
    margin-bottom: 12px; } }
.visual_img a {
  display: block;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
.visual_img a .visual_img_main img {
  width: 100%;
  height: auto; }
/*@media screen and (min-width: 961px) {*/
/*  .visual_img a:hover {*/
/*    -webkit-filter: drop-shadow(3px 3px 0 lime);*/
/*    filter: drop-shadow(3px 3px 0 lime); } }*/
.visual_img .visual_title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180px;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.93) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.93) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ed000000',GradientType=0 );
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 0 0 15px; }
@media screen and (max-width: 960px) {
  .visual_img .visual_title {
    height: 175px;
    padding: 0 0 18px; } }
.visual_img .visual_title p {
  font-size: 28px;
  color: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  line-height: 1.357em;
  font-style: italic;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden; }
@media screen and (max-width: 960px) {
  .visual_img .visual_title p {
    font-size: 20px;
    line-height: 1.4em; } }
.visual_content {
  width: 285px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px; }
@media screen and (max-width: 960px) {
  .visual_content {
    width: 100%;
    padding: 40px 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }
.visual_content_frame {
  position: absolute;
  pointer-events: none; }
.visual_content_frame_01 {
  width: 100%;
  height: 61px;
  top: 0; }
@media screen and (max-width: 960px) {
  .visual_content_frame_01 {
    width: calc(100% - 20px); } }
.visual_content_frame_01:before {
  content: '';
  display: block;
  width: calc(100% - 16px);
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0; }
.visual_content_frame_01:after {
  content: '';
  display: block;
  width: 1px;
  height: 20px;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0;
  right: 16px;
  background-color: #fff; }
.visual_content_frame_01--item {
  position: absolute;
  display: block;
  background-color: #fff; }
.visual_content_frame_01--item:first-child {
  width: 1px;
  height: 20px;
  top: 14px;
  right: 2px; }
.visual_content_frame_01--item:nth-child(2) {
  width: 3px;
  height: 23px;
  right: 1px;
  bottom: 0; }
@media screen and (max-width: 960px) {
  .visual_content_frame_01--item:nth-child(2) {
    display: none; } }
.visual_content_frame_02 {
  width: 100%;
  height: 64px;
  bottom: 0; }
@media screen and (max-width: 960px) {
  .visual_content_frame_02 {
    width: calc(100% - 20px); } }
.visual_content_frame_02:before {
  content: '';
  display: block;
  width: calc(100% - 16px);
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0; }
.visual_content_frame_02:after {
  content: '';
  display: block;
  width: 1px;
  height: 20px;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: -6px;
  left: 2px;
  background-color: #fff; }
.visual_content_frame_02--item {
  position: absolute;
  display: block;
  background-color: #fff; }
.visual_content_frame_02--item:first-child {
  width: 1px;
  height: 40px;
  bottom: 14px;
  left: 2px; }
.visual_content_price {
  margin-bottom: 15px; }
@media screen and (max-width: 960px) {
  .visual_content_price {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px; } }
.visual_content_supporter {
  margin-bottom: 20px; }
@media screen and (max-width: 960px) {
  .visual_content_supporter {
    margin-right: 40px; } }
.visual_content_heart {
  text-align: center; }
@media screen and (max-width: 960px) {
  .visual_content_heart {
    height: 44px; }
  .visual_content_heart a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; } }
.visual_content_heart .mylistLink {
  -webkit-transition: 0.4s;
  transition: 0.4s; }
@media screen and (min-width: 961px) {
  .visual_content_heart a:hover .mylistLink {
    -webkit-animation: logoFlash 2.8s step-start infinite;
    animation: logoFlash 2.8s step-start infinite; }
  .visual_content_heart a:hover .mylistLink_text {
    text-decoration: none; } }
@media screen and (max-width: 960px) {
  .visual_content_area {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; } }

.list--item_award {
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  top: 5px;
  left: 5px;
  pointer-events: none;
  z-index: 3;
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3)); }
@media screen and (max-width: 960px) {
  .list--item_award {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    top: 3px;
    left: 3px; } }
.list--item_award_content {
  width: 100%;
  height: 100%;
  position: relative; }
.list--item_award_content_img {
  width: 47px;
  height: 30px;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; }
@media screen and (max-width: 960px) {
  .list--item_award_content_img {
    width: 36px;
    height: 20px; } }
.list--item_award_content_label {
  width: 128px;
  height: 30px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
@media screen and (max-width: 960px) {
  .list--item_award_content_label {
    width: 98px;
    height: 20px; } }
.list--item_award_content_label:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1; }
.list--item_award_content_label p {
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-style: italic;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center; }
@media screen and (max-width: 960px) {
  .list--item_award_content_label p {
    font-size: 12px; } }
.list--item_award_content_label p:after {
  content: '';
  display: inline-block;
  width: 29px;
  height: 12px;
  background-image: url(../img/award_border.png);
  background-size: cover;
  margin-left: 2px; }
@media screen and (max-width: 960px) {
  .list--item_award_content_label p:after {
    width: 22px;
    height: 9px; } }
.list--item_award_content_upper {
  position: absolute;
  width: calc(100% - 52px);
  height: 3px;
  top: 0;
  right: 0; }
@media screen and (max-width: 960px) {
  .list--item_award_content_upper {
    width: calc(100% - 34px); } }
.list--item_award_content_upper:before {
  content: '';
  display: block;
  width: 35px;
  height: 3px;
  position: absolute;
  left: -52px;
  top: 34px; }
.list--item_award_content_upper:after {
  content: '';
  display: block;
  width: 3px;
  height: 41px;
  position: absolute;
  left: -20px;
  bottom: -33px;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(28deg);
  -ms-transform: rotate(28deg);
  transform: rotate(28deg);
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
@media screen and (max-width: 960px) {
  .list--item_award_content_upper:before, .list--item_award_content_upper:after {
    display: none; } }
.list--item_award_content_right {
  position: absolute;
  width: 3px;
  height: calc(100% - 20px);
  top: 0;
  right: 0; }
@media screen and (max-width: 960px) {
  .list--item_award_content_right {
    display: none; } }
.list--item_award_content_right:before {
  content: '';
  display: block;
  width: 3px;
  height: 29px;
  position: absolute;
  left: -20px;
  bottom: -18px;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award_content_bottom {
  position: absolute;
  width: calc(100% - 20px);
  height: 3px;
  bottom: 0;
  left: 0; }
@media screen and (max-width: 960px) {
  .list--item_award_content_bottom {
    display: none; } }
.list--item_award_content_left {
  position: absolute;
  width: 3px;
  height: calc(100% - 34px);
  top: 34px;
  left: 0; }
@media screen and (max-width: 960px) {
  .list--item_award_content_left {
    top: 0;
    height: 36%; } }
.list--item_award--gold .list--item_award_content_img {
  background-image: url(../img/award_logo--gold.png); }
.list--item_award--gold .list--item_award_content_label {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award--gold .list--item_award_content_upper {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award--gold .list--item_award_content_upper:before, .list--item_award--gold .list--item_award_content_upper:after {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award--gold .list--item_award_content_right {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award--gold .list--item_award_content_right:before {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award--gold .list--item_award_content_bottom {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award--gold .list--item_award_content_left {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #f2b00a; }
.list--item_award--silver .list--item_award_content_img {
  background-image: url(../img/award_logo--silver.png); }
.list--item_award--silver .list--item_award_content_label {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #808080; }
.list--item_award--silver .list--item_award_content_upper {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #808080; }
.list--item_award--silver .list--item_award_content_upper:before, .list--item_award--silver .list--item_award_content_upper:after {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #808080; }
.list--item_award--silver .list--item_award_content_right {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #808080; }
.list--item_award--silver .list--item_award_content_right:before {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #808080; }
.list--item_award--silver .list--item_award_content_bottom {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #808080; }
.list--item_award--silver .list--item_award_content_left {
  background-image: url(../img/bg_overlay_op02.png);
  background-color: #808080; }

body.returnOn {
  height: 100%;
  overflow: hidden; }

@media screen and (max-width: 960px) {
  .project_detail footer {
    padding-bottom: 70px; } }

.detail_visual {
  padding: 0; }
.detail_visual .visual_content {
  margin-bottom: 78px; }
.detail_visual .visual_content .visual_content_supporter, .detail_visual .visual_content .days--visual {
  margin-bottom: 0; }
.detail_visual .visual_content_area {
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: 0;
  left: 0;
  z-index: 997;
  background-color: #1a1a1a;
  padding: 0 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
.detail_visual .visual_content_area .link {
  margin-bottom: 0; }

.detail_summary_text {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  font-size: 14px;
  color: #fff;
  line-height: 1.42em;
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
.detail_summary_text:after {
  content: '';
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(79, 79, 79, 0) 42%, black 99%, black 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(79, 79, 79, 0) 42%, black 99%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; }
.detail_summary_text.textHidden:after {
  display: block; }

.detail_more {
  text-align: center;
  margin-top: 25px; }
@media screen and (min-width: 961px) {
  .detail_more:hover {
    cursor: pointer; }
  .detail_more:hover p {
    text-decoration: none; } }
.detail_more p {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  text-decoration: underline;
  line-height: 1.5em; }
.detail_more--white p {
  color: #fff; }
.detail_more--black p {
  color: #1a1a1a; }

.detail_whiteBox {
  width: 100%;
  background-color: #fff;
  color: #1a1a1a;
  padding: 20px; }
@media screen and (max-width: 960px) {
  .detail_whiteBox {
    padding: 30px 10px; } }

.box_title {
  color: black;
  width: 100%;
  height: 40px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px; }
.box_title--black .box_title_bg {
  background-color: #fff;
  border-top: 1px solid #1a1a1a;
  border-right: 1px solid #1a1a1a;
  border-left: 1px solid #1a1a1a; }
.box_title--black .box_title_bg:before {
  border-left: 1px solid #1a1a1a;
  border-bottom: 1px solid #1a1a1a; }
.box_title_bg {
  position: absolute;
  width: 100%;
  height: calc(100% - 10px);
  top: 0;
  left: 0; }
.box_title_bg:before, .box_title_bg:after {
  content: '';
  display: block;
  position: absolute; }
.box_title_bg:before {
  width: calc(100% - 9px);
  height: 10px;
  bottom: -10px;
  left: -1px; }
.box_title_bg:after {
  width: 1px;
  height: 10px;
  background-color: #1a1a1a;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skewX(-45deg);
  -ms-transform: skewX(-45deg);
  transform: skewX(-45deg);
  bottom: -10px;
  right: 9px; }
.box_title_text {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-size: 20px;
  font-weight: 800;
  text-align: center; }
@media screen and (max-width: 960px) {
  .box_title_text {
    font-size: 18px; } }

@-webkit-keyframes boxLinkFlash {
  0% {
    opacity: 0.2; }
  1% {
    opacity: 1; }
  2% {
    opacity: 0.8; }
  3% {
    opacity: 1; }
  4% {
    opacity: 0.3; }
  5% {
    opacity: 1; }
  6% {
    opacity: 1; }
  7% {
    opacity: 0.6; }
  8% {
    opacity: 1; }
  9% {
    opacity: 0.5; }
  10% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes boxLinkFlash {
  0% {
    opacity: 0.2; }
  1% {
    opacity: 1; }
  2% {
    opacity: 0.8; }
  3% {
    opacity: 1; }
  4% {
    opacity: 0.3; }
  5% {
    opacity: 1; }
  6% {
    opacity: 1; }
  7% {
    opacity: 0.6; }
  8% {
    opacity: 1; }
  9% {
    opacity: 0.5; }
  10% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.detail_qa {
  margin-bottom: 50px; }
@media screen and (max-width: 960px) {
  .detail_qa {
    margin-bottom: 60px; } }

.detail_story_content p {
  color: #fff;
  font-size: 14px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  line-height: 1.65em; }
@media screen and (max-width: 960px) {
  .detail_story_content p {
    font-size: 12px;
    line-height: 1.5em; } }
.detail_story_content img {
  width: 100%;
  height: auto;
  margin-bottom: 10px; }

.detail_cast_content {
  margin-bottom: 27px; }
@media screen and (max-width: 960px) {
  .detail_cast_content {
    margin-bottom: 16px; } }

.form--item {
  width: 100%; }
.form--item:not(:first-child) {
  margin-top: 20px; }
.form--item_name {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  font-size: 14px;
  margin-bottom: 9px; }
.form--item_password span {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  color: #808080;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  font-size: 10px; }
.form--item_input {
  width: 100%;
  position: relative;
  z-index: 2; }
.form--item_input--black .form--item_input_bg {
  background-color: #fff;
  border-top: 1px solid #1a1a1a;
  border-right: 1px solid #1a1a1a;
  border-left: 1px solid #1a1a1a; }
.form--item_input--black .form--item_input_bg:before {
  border-left: 1px solid #1a1a1a;
  border-bottom: 1px solid #1a1a1a; }
.form--item_input_bg {
  position: absolute;
  width: 100%;
  height: calc(100% - 8px);
  top: 0;
  left: 0;
  z-index: -1; }
.form--item_input_bg:before, .form--item_input_bg:after {
  content: '';
  display: block;
  position: absolute; }
.form--item_input_bg:before {
  width: calc(100% - 9px);
  height: 10px;
  bottom: -10px;
  left: -1px; }
.form--item_input_bg:after {
  width: 1px;
  height: 10px;
  background-color: #1a1a1a;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skewX(-45deg);
  -ms-transform: skewX(-45deg);
  transform: skewX(-45deg);
  bottom: -10px;
  right: 9px; }
.form--item_input input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 13px 10px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left; }
.form--item_input input::-webkit-input-placeholder {
  color: #cbcbcb; }
.form--item_input input:-ms-input-placeholder {
  color: #cbcbcb; }
.form--item_input input::placeholder {
  color: #cbcbcb; }
@media screen and (max-width: 960px) {
  .form--item_input input::-webkit-input-placeholder {
    padding: 5px 0; }
  .form--item_input input:-ms-input-placeholder {
    padding: 5px 0; }
  .form--item_input input::placeholder {
    padding: 5px 0; } }
.form--item_input input:-webkit-autofill {
  background-color: #fff; }
@media screen and (max-width: 960px) {
  .form--item_input input {
    height: 30px;
    padding: 8px 6px 6px; } }
.form--item_radio {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
.form--item_radio--item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  vertical-align: middle; }
.form--item_radio--item:not(:last-child) {
  margin-right: 18px; }
.form--item_radio--item input {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid #1a1a1a; }
.form--item_radio--item input:checked + .form--item_radio--item_check {
  display: block; }
.form--item_radio--item_check {
  width: 6px;
  height: 6px;
  background-color: #ff0000;
  border-radius: 3px;
  position: absolute;
  left: 3px;
  top: 50%;
  margin-top: -3px;
  display: none; }
.form--item_radio--item span {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-size: 14px;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  margin-left: 4px; }
.form--item_select {
  margin-bottom: 40px; }
.form--item_select_year, .form--item_select_month, .form--item_select_day {
  display: inline-block;
  position: relative; }
.form--item_select_year:after, .form--item_select_month:after, .form--item_select_day:after {
  content: '';
  width: 11px;
  height: 9px;
  background-image: url(../img/select_arrow.png);
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 31px;
  top: 50%;
  margin-top: -4px;
  pointer-events: none; }
.form--item_select_year select {
  width: 90px; }
.form--item_select_month select, .form--item_select_day select {
  width: 65px; }
.form--item_select select {
  height: 32px;
  border: 1px solid #1a1a1a;
  font-size: 14px;
  padding: 0 9px;
  position: relative; }
.form--item_select span {
  font-size: 14px;
  padding-left: 6px; }

.form_submit {
  margin-top: 40px; }
@media screen and (max-width: 960px) {
  .form_submit {
    margin-top: 30px; } }

.form_lost {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-size: 10px;
  width: 100%;
  text-decoration: underline;
  margin-top: 9px;
  text-align: center;
  display: inline-block;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
.form_lost:hover {
  cursor: pointer; }
@media screen and (min-width: 961px) {
  .form_lost:hover {
    color: #ff0000; } }

.form_terms {
  text-align: center; }
.form_terms input {
  width: 15px;
  height: 15px;
  border: 1px solid #1a1a1a;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  border-radius: 0; }
.form_terms label {
  padding-left: 6px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-size: 12px;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left; }
.form_terms label a {
  text-decoration: underline; }

.mypage_content_message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
@media screen and (max-width: 960px) {
  .mypage_content_message {
    display: block;
    overflow: hidden;
    min-height: 800px; } }
@media screen and (max-width: 960px) {
  .mypage_content_message.active .mypage_content_message_project {
    left: calc(-100% + -10px); }
  .mypage_content_message.active .mypage_content_message_chat {
    left: 0; } }
.mypage_content_message_project {
  width: 260px;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
@media screen and (min-width: 961px) {
  .mypage_content_message_project {
    max-height: 700px;
    padding-right: 20px;
    overflow: scroll;
    -ms-overflow-style: none; }
  .mypage_content_message_project::-webkit-scrollbar {
    display: none; } }
@media screen and (max-width: 960px) {
  .mypage_content_message_project {
    width: 100%;
    float: left;
    position: relative;
    left: 0; } }
.mypage_content_message_project--item:not(:last-child) {
  margin-bottom: 10px; }
.mypage_content_message_project--item a {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: block; }
@media screen and (min-width: 961px) {
  .mypage_content_message_project--item a:hover {
    -webkit-filter: drop-shadow(3px 3px 0 lime);
    filter: drop-shadow(3px 3px 0 lime); } }
@media screen and (max-width: 960px) {
  .mypage_content_message_project--item a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%; } }
@media screen and (max-width: 960px) {
  .mypage_content_message_project--item_area {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%; } }
.mypage_content_message_project--item_img {
  width: 100%;
  height: 134px;
  background-size: cover;
  background-position: center;
  position: relative; }
@media screen and (max-width: 960px) {
  .mypage_content_message_project--item_img {
    height: 76px; } }
.mypage_content_message_project--item_detail {
  width: 100%;
  z-index: 2;
  position: relative;
  padding: 10px; }
@media screen and (max-width: 960px) {
  .mypage_content_message_project--item_detail {
    width: 220px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 22px 16px 10px; } }
.mypage_content_message_project--item_detail_bg {
  position: absolute;
  width: 100%;
  height: calc(100% - 16px);
  background-color: #1a1a1a;
  top: 0;
  left: 0;
  z-index: -1; }
.mypage_content_message_project--item_detail_bg:before, .mypage_content_message_project--item_detail_bg:after {
  content: '';
  display: block;
  height: 16px;
  position: absolute;
  bottom: -16px; }
.mypage_content_message_project--item_detail_bg:before {
  width: calc(100% - 16px);
  left: 0;
  background-color: #1a1a1a; }
.mypage_content_message_project--item_detail_bg:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 0;
  border-color: #1a1a1a transparent transparent transparent;
  right: 0; }
.mypage_content_message_project--item_detail_title {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  font-size: 12px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-style: italic;
  margin-bottom: 14px;
  line-height: 1.5em;
  color: #fff; }
@media screen and (max-width: 960px) {
  .mypage_content_message_project--item_detail_title {
    margin-bottom: 5px;
    position: relative;
    left: 5.5%; } }
.mypage_content_message_project--item_detail_option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
.mypage_content_message_chat {
  width: 482px;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
@media screen and (max-width: 960px) {
  .mypage_content_message_chat {
    width: 100%;
    height: 100%;
    padding: 0; } }
@media screen and (max-width: 960px) {
  .mypage_content_message_chat_back {
    margin-bottom: 13px;
    padding: 0 10px; }
  .mypage_content_message_chat_back:before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 13px;
    background-image: url(../img/return_back.png);
    background-size: cover; }
  .mypage_content_message_chat_back_text {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    display: inline-block;
    width: auto;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    margin-left: 9px;
    font-size: 12px; } }
@media screen and (max-width: 960px) and (max-width: 960px) {
  .mypage_content_message_chat_back_text {
    font-size: 12px;
    position: relative;
    top: -2px; } }
.mypage_content_message_chat_area {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none; }
.mypage_content_message_chat_area::-webkit-scrollbar {
  display: none; }
/* @media screen and (min-width: 961px) {
  .mypage_content_message_chat_area {
    max-height: 700px; } } */
@media screen and (max-width: 960px) {
  .mypage_content_message_chat_area {
    /* height: 500px; */
    padding: 0 10px; } }
.mypage_content_message_chat_area--item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
.mypage_content_message_chat_area--item:not(:last-child) {
  margin-bottom: 20px; }
.mypage_content_message_chat_area--item--client .mypage_content_message_chat_area--item_text {
  background-color: #e5e5e5;
  border: 1px solid #1a1a1a; }
.mypage_content_message_chat_area--item--customer .mypage_content_message_chat_area--item_text {
  margin-left: 42px;
  background-color: rgba(255, 0, 0, 0.3);
  border: 1px solid rgba(255, 0, 0, 0.6); }
.mypage_content_message_chat_area--item_img {
  width: 32px;
  height: 32px; }
.mypage_content_message_chat_area--item_img_icon {
  width: 32px;
  height: 32px;
  position: relative; }
.mypage_content_message_chat_area--item_img_icon_border {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; }
.mypage_content_message_chat_area--item_img_icon_main {
  width: 30px;
  height: 30px;
  position: relative;
  top: 1px;
  left: 1px; }
.mypage_content_message_chat_area--item_text {
  width: calc(100% - 42px);
  padding: 15px;
  border-radius: 10px; }
.mypage_content_message_chat_area--item_text p {
  font-size: 14px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  width: 100%;
  line-height: 1.428em;
  text-align: justify; }
.mypage_content_message_chat_area--item_date {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #666666;
  margin-top: 4px; }
.mypage_content_message_chat_field {
  width: 100%;
  height: 206px;
  background-color: #1a1a1a;
  padding: 14px; }
@media screen and (max-width: 960px) {
  .mypage_content_message_chat_field {
    height: 194px;
    padding: 10px; } }
.mypage_content_message_chat_field_input {
  position: relative;
  width: 100%;
  height: 120px;
  margin-bottom: 14px;
  z-index: 2; }
@media screen and (max-width: 960px) {
  .mypage_content_message_chat_field_input {
    margin-bottom: 10px; } }
.mypage_content_message_chat_field_input:before {
  content: '';
  display: block;
  width: calc(100% - 10px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fff; }
.mypage_content_message_chat_field_input_bg {
  position: relative;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }
.mypage_content_message_chat_field_input_bg:before {
  content: '';
  display: block;
  width: 10px;
  height: calc(100% - 10px);
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff; }
.mypage_content_message_chat_field_input_bg:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  right: 0;
  bottom: 0; }
.mypage_content_message_chat_field_input textarea {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.2em;
  color: #1a1a1a;
  z-index: 2;
  resize: none; }
.mypage_content_message_chat_field_input textarea::-webkit-input-placeholder {
  color: #cbcbcb; }
.mypage_content_message_chat_field_input textarea:-ms-input-placeholder {
  color: #cbcbcb; }
.mypage_content_message_chat_field_input textarea::placeholder {
  color: #cbcbcb; }
.mypage_content_message_chat_field_submit {
  height: 44px;
  position: relative;
  z-index: 2; }
.mypage_content_message_chat_field_submit:before {
  content: '';
  display: block;
  width: calc(100% - 10px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
.mypage_content_message_chat_field_submit_bg {
  position: relative;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }
.mypage_content_message_chat_field_submit_bg:before {
  content: '';
  display: block;
  width: 10px;
  height: calc(100% - 10px);
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
.mypage_content_message_chat_field_submit_bg:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
.mypage_content_message_chat_field_submit input {
  width: 100%;
  height: 100%;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  width: 100%;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.2em;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
@media screen and (min-width: 961px) {
  .mypage_content_message_chat_field_submit:hover:before {
    background-color: #ff0000; }
  .mypage_content_message_chat_field_submit:hover .mypage_content_message_chat_field_submit_bg:before {
    background-color: #ff0000; }
  .mypage_content_message_chat_field_submit:hover .mypage_content_message_chat_field_submit_bg:after {
    border-color: #ff0000 transparent transparent transparent; }
  .mypage_content_message_chat_field_submit:hover input {
    color: #fff; } }

.template {
  width: 100%;
  background-color: #fff;
  padding: 44px 30px; }
@media screen and (max-width: 960px) {
  .template {
    padding: 22px 10px; } }
.template--white {
  width: 100%;
  padding: 30px;
  background-color: #fff;
  margin: 100px 0; }
@media screen and (max-width: 960px) {
  .template--white {
    padding: 10px; } }
.template--black {
  width: 100%;
  padding: 30px;
  background-color: #1a1a1a;
  margin: 100px 0; }
@media screen and (max-width: 960px) {
  .template--black {
    padding: 10px; } }
.template .link {
  margin-bottom: 100px; }
@media screen and (max-width: 960px) {
  .template .link {
    margin: 0 auto 60px; } }
.template .color {
  width: 137px;
  height: 137px;
  display: inline-block;
  margin: 0 20px; }
@media screen and (max-width: 960px) {
  .template .color {
    width: calc(50% - 40px); } }
.template .color--black {
  background-color: #1a1a1a; }
.template .color--white {
  background-color: #fff;
  border: 1px solid #1a1a1a; }
.template .color--red {
  background-color: #ff0000; }
.template .color--green {
  background-color: #00ff00; }
.template .color--blue {
  background-color: #0000ff; }
.template .text-l, .template .text-m, .template .text-s {
  margin-bottom: 40px; }
.template .progress, .template .form_template, .template .error, .template .form_button, .template .form_check, .template .form--item_radio, .template .item_title, .template .sub_title {
  margin-bottom: 40px; }

.text--white {
  color: #fff; }

.text--black {
  color: #1a1a1a; }

.text-l {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  width: 100%;
  font-style: italic;
  font-size: 28px;
  line-height: 38px; }
@media screen and (max-width: 960px) {
  .text-l {
    font-size: 20px;
    line-height: 1.4em; } }

.text-m {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  width: 100%;
  font-size: 20px;
  line-height: 34px; }
@media screen and (max-width: 960px) {
  .text-m {
    font-size: 18px;
    line-height: 1.5em; } }

.text-s {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  width: 100%;
  font-size: 16px;
  line-height: 24px; }
@media screen and (max-width: 960px) {
  .text-s {
    font-size: 12px;
    line-height: 1.45em; } }

.text-c {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  width: 100%;
  font-size: 14px;
  line-height: 20px; }
@media screen and (max-width: 960px) {
  .text-c {
    line-height: 1.428em; } }

.progress {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
@media screen and (max-width: 960px) {
  .progress {
    width: 100%; } }
.progress .progress_meter {
  width: 100%;
  height: 20px;
  position: relative;
  background-color: #1a1a1a; }
@media screen and (max-width: 960px) {
  .progress .progress_meter {
    height: 16px; } }
.progress .progress_meter_fill {
  background-color: #ff0000;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-blend-mode: color-burn; }
.progress .progress_meter_fill:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  background: -webkit-linear-gradient(4deg, rgba(0, 0, 0, 0.6) 0%, white 100%);
  background: linear-gradient(86deg, rgba(0, 0, 0, 0.6) 0%, white 100%);
  mix-blend-mode: multiply; }

.item_title {
  padding-left: 15px;
  position: relative; }
.item_title:before {
  content: '';
  width: 5px;
  height: 100%;
  background-color: #1a1a1a;
  position: absolute;
  left: 0;
  top: 0; }
.item_title p {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  font-size: 16px;
  line-height: 20px; }
@media screen and (max-width: 960px) {
  .item_title p {
    font-size: 14px; } }
.item_title--required p:after {
  content: '必須';
  font-size: 12px;
  color: #fff;
  display: inline-block;
  background-color: #ff0000;
  padding: 2px 10px; }
.item_title--terms p {
  font-size: 20px;
  line-height: 1.35em; }
@media screen and (max-width: 960px) {
  .item_title--terms p {
    font-size: 18px; } }
.item_title--terms p span {
  font-size: 16px; }
@media screen and (max-width: 960px) {
  .item_title--terms p span {
    font-size: 14px; } }
.item_title--terms p span a {
  text-decoration: underline;
  color: #0000ff; }

.form_check {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
.form_check input {
  width: 15px;
  height: 15px;
  border: 1px solid #1a1a1a;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  border-radius: 0; }
.form_check label {
  padding-left: 6px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-size: 16px;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left; }
@media screen and (max-width: 960px) {
  .form_check label {
    font-size: 14px; } }
.form_check label a {
  text-decoration: underline; }

.form_button {
  width: 128px;
  height: 32px;
  position: relative;
  z-index: 2; }
.form_button a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
.form_button a:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent transparent #1a1a1a;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s; }
@media screen and (max-width: 960px) {
  .form_button a:after {
    right: 1px; } }
@media screen and (min-width: 961px) {
  .form_button a:hover:after {
    opacity: 1; }
  .form_button a:hover .form_button_bg {
    background-color: #1a1a1a; }
  .form_button a:hover .form_button_bg:before, .form_button a:hover .form_button_bg:after {
    background-color: #1a1a1a; }
  .form_button a:hover .form_button_text {
    color: #fff; } }
.form_button_bg {
  position: absolute;
  width: 100%;
  height: calc(100% - 10px);
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fff;
  border-top: 1px solid #1a1a1a;
  border-right: 1px solid #1a1a1a;
  border-left: 1px solid #1a1a1a;
  -webkit-transition: 0.1s;
  transition: 0.1s; }
.form_button_bg:before, .form_button_bg:after {
  content: '';
  display: block;
  position: absolute;
  -webkit-transition: 0.1s;
  transition: 0.1s; }
.form_button_bg:before {
  width: calc(100% - 9px);
  height: 10px;
  bottom: -10px;
  left: -1px;
  border-left: 1px solid #1a1a1a;
  border-bottom: 1px solid #1a1a1a; }
@media screen and (max-width: 960px) {
  .form_button_bg:before {
    width: calc(100% - 8px); } }
.form_button_bg:after {
  width: 1px;
  height: 10px;
  background-color: #1a1a1a;
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transform: skewX(-45deg);
  -ms-transform: skewX(-45deg);
  transform: skewX(-45deg);
  bottom: -10px;
  right: -1px; }
.form_button_text {
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: #1a1a1a; }
@media screen and (max-width: 960px) {
  .form_button_text {
    font-size: 10px; } }

.error {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px; }
@media screen and (max-width: 960px) {
  .error {
    padding: 10px; } }
.error_02 {
  background-color: #ff0000; }
.error_02 p {
  font-size: 14px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  color: #fff;
  text-align: center;
  line-height: 1.45em; }
@media screen and (max-width: 960px) {
  .error_02 p {
    font-size: 12px; } }

.form_template {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
@media screen and (max-width: 960px) {
  .form_template {
    display: block; } }
.form_template_title {
  width: 210px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  font-size: 16px;
  margin-top: 12px; }
@media screen and (max-width: 960px) {
  .form_template_title {
    margin-top: 0;
    margin-bottom: 10px; } }
.form_template_title--required {
  color: #ff0000; }
.form_template_area {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }
.form_template_area_block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
.form_template_area_block:not(:last-child) {
  margin-right: 40px; }
.form_template_area_block--option {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  width: 100%;
  display: inline-block;
  font-size: 12px;
  color: #989898;
  margin-top: 10px; }
.form_template_area_block--error {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  width: 100%;
  display: inline-block;
  font-size: 12px;
  color: #ff0000;
  margin-top: 10px; }
.form_template_area_label {
  display: inline-block;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  margin-right: 10px; }
.form_template_input {
  width: 100%;
  position: relative;
  z-index: 2;
  display: inline-block; }
.form_template_input--black .form_template_input_bg {
  background-color: #fff;
  border-top: 1px solid #1a1a1a;
  border-right: 1px solid #1a1a1a;
  border-left: 1px solid #1a1a1a; }
.form_template_input--black .form_template_input_bg:before {
  border-left: 1px solid #1a1a1a;
  border-bottom: 1px solid #1a1a1a; }
.form_template_input_bg {
  position: absolute;
  width: 100%;
  height: calc(100% - 8px);
  top: 0;
  left: 0;
  z-index: -1; }
.form_template_input_bg:before, .form_template_input_bg:after {
  content: '';
  display: block;
  position: absolute; }
.form_template_input_bg:before {
  width: calc(100% - 9px);
  height: 10px;
  bottom: -10px;
  left: -1px; }
.form_template_input_bg:after {
  width: 1px;
  height: 10px;
  background-color: #1a1a1a;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skewX(-45deg);
  -ms-transform: skewX(-45deg);
  transform: skewX(-45deg);
  bottom: -10px;
  right: 9px; }
.form_template_input input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 13px 10px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left; }
.form_template_input input::-webkit-input-placeholder {
  color: #cbcbcb; }
.form_template_input input:-ms-input-placeholder {
  color: #cbcbcb; }
.form_template_input input::placeholder {
  color: #cbcbcb; }
@media screen and (max-width: 960px) {
  .form_template_input input::-webkit-input-placeholder {
    padding: 5px 0; }
  .form_template_input input:-ms-input-placeholder {
    padding: 5px 0; }
  .form_template_input input::placeholder {
    padding: 5px 0; } }
.form_template_input input:-webkit-autofill {
  background-color: #fff; }
@media screen and (max-width: 960px) {
  .form_template_input input {
    height: 30px;
    padding: 8px 6px 6px; } }

/*************************
KeyFrames
material-uiのmakeStylesでkeyframeが使用できないためこちらに退避
see https://github.com/mui-org/material-ui/issues/21011
**************************/
@keyframes scaleUp {
  0% {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: scale(1.02);
  }
}

@keyframes scaleUpCorner {
  0% {
    borderColor: #fff transparent transparent transparent;
  }
  100% {
    borderColor: #fff584 transparent transparent transparent;
  }
}

@keyframes textFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleDownHide {
  0% {
    transform: scale(1.02);
    visibility: visible;
    opacity: 1;
  }
  50% {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    visibility: hidden;
    opacity: 0;
  }
}

@media print {
  .no-print {
    display: none;
    visibility: hidden;
  }
}

@keyframes flowingComment {
  0% { transform: translateX(calc(100vw))}
  100% { transform: translateX(-100%)}
}

/*************************
zendeskの標準launcherを消すためのcss
**************************/
#launcher {
  display: none;
}


.section {
  display: none;
}

.section.visible {
  display: block;
}

